import React from "react";
import { Link } from "@StarberryUtils";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import { Button, Form, Container, FormControl, Row, InputGroup, Col, Dropdown} from "react-bootstrap";
import "./MortgagePaymentCalculator.scss";
import {
    BAC_PAGE_URL
     } from "@Components/common/site/constants";

const MortgagePaymentCalculator = () => {


  
    return (
        <React.Fragment>
            <section className="calculator-payment-section">
                <Container>
                    <Row>
                        <Col lg={5} xl={3}>
                        <ScrollAnimation animateOnce={true} animateIn="fadeInDown">
                            <h2>Calculate your mortgage payments</h2>
                            <p>Calculate your monthly repayment using our handy Mortgage Calculator Tool.</p>
                            <Link to={BAC_PAGE_URL.alias} className="btn btn-outline d-none d-lg-inline-flex">Book a Consultation</Link>
                        </ScrollAnimation>
                        </Col>
                        <Col lg={7} xl={9}>
                        <ScrollAnimation animateOnce={true} animateIn="fadeIn"> 
                        <div className="calculator-form">
                        <Form>
                        
                        <Row>
                        <Col md={6}>
                           
                        <Form.Group controlId="propertyPrice">
                            <Form.Label>Property Price</Form.Label>
                            <div className="addonrow">
                            <span className="input-addon-left">&#163;</span>
                            <Form.Control type="number" placeholder="1,150,000" />
                            <span className="input-addon-right d-none"></span>
                            </div>
                        </Form.Group>
                        </Col>
                        <Col md={6}>    
                        <Form.Group controlId="deposit">
                            <Form.Label>Deposit</Form.Label>
                            <div className="addonrow">
                            <span className="input-addon-left">&#163;</span>
                            <Form.Control type="number" placeholder="1,150,000" />
                            <span className="input-addon-right d-none"></span>
                            </div>
                        </Form.Group>
                        </Col>
                        </Row>

                        <Row>
                        <Col md={6}> 
                        <Form.Group controlId="repaymentTerm" className="input-bot-mg">
                            <Form.Label>Repayment Term</Form.Label>
                            <div className="addonrow">
                            <span className="input-addon-left d-none">&#163;</span>
                            <Form.Control type="number" placeholder="25 Years" />
                            <span className="input-addon-right d-none"></span>
                            </div>
                        </Form.Group>
                        </Col>
                        <Col md={6}>
                        <Form.Group controlId="deposit" className="input-bot-mg">
                            <Form.Label>Deposit</Form.Label>
                            <div className="addonrow">
                            <span className="input-addon-left d-none">&#163;</span>
                            <Form.Control type="number" placeholder="2"/>
                            <span className="input-addon-right">%</span>
                            </div>
                        </Form.Group>
                        </Col>
                        </Row>
                        <Row className="form-footer">
                            <Col md={12}>
                            <Form.Label>Monthly Payment</Form.Label>
                            <div className="amount">
                                <span className="main-amount">£4,238.00</span><span className="main-amount-side-text">per month</span>
                            </div>
                            </Col>
                        </Row>
                        </Form>
                        </div>
                        </ScrollAnimation>

                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default MortgagePaymentCalculator;