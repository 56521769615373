import React, {useState, useEffect} from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import BreadcrumbPage from "../../Components/StaticPage/BreadcrumbPage/BreadcrumbPage";
import News from "../../Components/StaticPage/News/News";
import MortgagePaymentCalculator from "../../Components/StaticPage/MortgagePaymentCalculator/MortgagePaymentCalculator";
import BuyingProperty from "../../Components/StaticPage/BuyingProperty/BuyingProperty";
import AgentsTeam from "../../Components/StaticPage/AgentsTeam/AgentsTeam";
import Ethics from "../../Components/StaticPage/Ethics/Ethics";
import RelatedProperties from "../../Components/StaticPage/RelatedProperties/RelatedProperties";
import Explore from "../../Components/StaticPage/Explore/Explore";
import "./StaticPage.scss";
const StaticPage = () => {

    const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 60)
    })
  }, [])


    return (
        <React.Fragment>
            <div className={scroll ? "header-logo-black menu-transparent menu-scroll" : "header-logo-black menu-transparent"}><Header/></div>
            <News/>
            <div className="buying-property-mortgage-calculator">
            <MortgagePaymentCalculator/>
            </div>
            {/* <AgentsTeam/> */}
            {/* <Ethics/> */}
            <Explore/>
            <div className="breadcrumb-other-page"><BreadcrumbPage/></div>
            <Footer/>
        </React.Fragment>
    )
}

export default StaticPage